import React from 'react';
import './WhyChooseUs.css';

function WhyChooseUs() {
    return (
        <section className="why-choose-us" id="why-aroepa">
            <h2>Waarom Aroepa-Fit?</h2>
            <div className="reasons">
                <div className="reason">
                    <h3>Professional Trainers</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel venenatis tortor.</p>
                </div>
                <div className="reason">
                    <h3>Modern Equipment</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Nullam in eros et purus facilisis posuere.</p>
                </div>
                <div className="reason">
                    <h3>Personalized Programs</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor nisi vitae massa faucibus tincidunt.</p>
                </div>
                <div className="reason">
                    <h3>Flexible Schedule</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse potenti. Mauris vitae eros orci.</p>
                </div>
            </div>
        </section>
    );
}

export default WhyChooseUs;
