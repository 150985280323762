import React from 'react';
import './GroupTraining.css';

function GroupTraining() {
    return (
        <section className="group-training" id="group-training">
            <h2>Groepslessen</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel venenatis tortor.</p>
        </section>
    );
}

export default GroupTraining;
