import React from 'react';
import Packages from "./Packages";
import './Training.css';
function Training() {
    return (
        <div className="Training" id="training">
            <h2 className="training-title">Training</h2>
            <p className="training-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium, beatae!</p>
            <Packages />
        </div>
    );
}

export default Training;
