import React from 'react';
import './Price.css';

function Price() {
    return (
        <section className="price" id="prices">
            <h2>Prijzen</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel venenatis tortor.</p>
        </section>
    );
}

export default Price;
