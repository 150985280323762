import React from 'react';
import './Packages.css';
import {FaCheck} from "react-icons/fa";
import { Link } from 'react-router-dom';

function Packages() {
    return (
        <div className="Packages">
            <div className="package-row-one">
                <Link to="/groepslessen" className="package-link">
                    <div className="package-one">
                        <h1 className="package-title">Groepslessen</h1>
                        <h2 className="package-subtitle">Lorem ipsum dolor sit amet.</h2>
                        <p className="package-paragraph">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde,
                            vero.</p>
                        <li className="li-package-features-">
                            <ul className="ul-package-feature"><FaCheck/> <p>Lorem ipsum dolor.</p></ul>
                            <ul className="ul-package-feature"><FaCheck/> <p>Lorem ipsum dolor.</p></ul>
                            <ul className="ul-package-feature"><FaCheck/> <p>Lorem ipsum dolor.</p></ul>
                        </li>
                        <p>Klik hier voor meer informatie</p>
                    </div>
                </Link>

                <div className="package-two">
                    <h1 className="package-title">Personal Training</h1>
                    <h2 className="package-subtitle">Lorem ipsum dolor sit amet.</h2>
                    <p className="package-paragraph">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde,
                        vero.</p>
                    <li className="li-package-features-">
                        <ul className="ul-package-feature"><FaCheck/> <p>Lorem ipsum dolor.</p></ul>
                        <ul className="ul-package-feature"><FaCheck/> <p>Lorem ipsum dolor.</p></ul>
                        <ul className="ul-package-feature"><FaCheck/> <p>Lorem ipsum dolor.</p></ul>
                    </li>
                    <p>Klik hier voor meer informatie</p>
                </div>
            </div>


            <div className="package-row-two">
                <div className="package-three">
                    <h1 className="package-title">Begeleiding</h1>
                    <h2 className="package-subtitle">Lorem ipsum dolor sit amet.</h2>
                    <p className="package-paragraph">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde,
                        vero.</p>
                    <li className="li-package-features-">
                        <ul className="ul-package-feature"><FaCheck/> <p>Lorem ipsum dolor.</p></ul>
                        <ul className="ul-package-feature"><FaCheck/> <p>Lorem ipsum dolor.</p></ul>
                        <ul className="ul-package-feature"><FaCheck/> <p>Lorem ipsum dolor.</p></ul>
                    </li>
                    <p>Klik hier voor meer informatie</p>
                </div>
                <div className="package-custom">
                    <h1 className="package-title">Proefles</h1>
                    <h2 className="package-subtitle">Lorem ipsum dolor sit amet.</h2>
                    <p className="package-paragraph">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde,
                        vero.</p>
                    <li className="li-package-features-">
                        <ul className="ul-package-feature"><FaCheck/> <p>Lorem ipsum dolor.</p></ul>
                        <ul className="ul-package-feature"><FaCheck/> <p>Lorem ipsum dolor.</p></ul>
                        <ul className="ul-package-feature"><FaCheck/> <p>Lorem ipsum dolor.</p></ul>
                    </li>
                    <p>Klik hier voor meer informatie</p>
                </div>
            </div>
        </div>
    );
}

export default Packages;
