import React from 'react';
import './Gallery.css';
import PICONE from '../assets/gallery/PICONE.png';
import PICTWO from '../assets/gallery/PICSIX.png';
import PICTHREE from '../assets/gallery/PICFIVE.png';
import PICFOUR from '../assets/gallery/PICFOUR.png';
import PICFIVE from '../assets/gallery/PICTHREE.png';
import PICSIX from '../assets/gallery/PICTWO.png';

function Gallery() {
    return (
        <div className="Gallery">
            <h1>Sfeer Impressie Groepstraining</h1>
            <div className="gallery-grid">
                <div className="gallery-item"><img src={PICONE} alt="Placeholder 1" /></div>
                <div className="gallery-item"><img src={PICTWO} alt="Placeholder 2" /></div>
                <div className="gallery-item"><img src={PICTHREE} alt="Placeholder 3" /></div>
                <div className="gallery-item"><img src={PICFOUR} alt="Placeholder 4" /></div>
                <div className="gallery-item"><img src={PICFIVE} alt="Placeholder 5" /></div>
                <div className="gallery-item"><img src={PICSIX} alt="Placeholder 6" /></div>
            </div>
        </div>
    );
}

export default Gallery;
