import React from 'react';
import './Location.css';
import GoogleMapsEmbed from "./GoogleMapsEmbed";

function Location() {
    return (
        <div className="Location">
            <section className="location-text">
                <h2>Dijckerhoek 72, 's-Gravenzande</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste mollitia neque nisi! Esse eveniet harum minima officia sint velit veritatis!</p>
            </section>
            <GoogleMapsEmbed />
        </div>
    );
}

export default Location;
