import React from 'react';
import './Footer.css';
import LogoAroepaFit from "../assets/LogoAroepaFit.png";
import Facebook from"../assets/Facebook.png"
import X from "../assets/X.png"
import LinkedIn from "../assets/LinkedIn.png";
import Instagram from "../assets/Instagram.png";
import TikTok from "../assets/TikTok.png";

import {MdOutlineMarkEmailUnread} from "react-icons/md";
import {IoIosCall} from "react-icons/io";

function Footer() {
    return (
        <footer>
            <div className="footer-logo">
                <img src={LogoAroepaFit} alt="Logo van Aroepa-Fit" />
            </div>
            <div className="footer-content">
                <div className="footer-contact">
                    <MdOutlineMarkEmailUnread fontSize="25px" />
                    <p>info@aroepafit.nl</p>
                </div>
                <div className="footer-contact">
                    <IoIosCall fontSize="25px" /> <p>060000000</p>
                </div>
                <div className="footer-social">
                    <a href="https://www.facebook.com/developbit.nl/" target="_blank" rel="noopener noreferrer">
                        <img src={Facebook} alt="Facebook" />
                    </a>
                    <a href="https://twitter.com/develop_bit" target="_blank" rel="noopener noreferrer">
                        <img src={X} alt="X (Twitter)" />
                    </a>
                    <a href="https://www.linkedin.com/company/develop-bit/" target="_blank" rel="noopener noreferrer">
                        <img src={LinkedIn} alt="LinkedIn" />
                    </a>
                    <a href="https://www.instagram.com/developbitt/" target="_blank" rel="noopener noreferrer">
                        <img src={Instagram} alt="Instagram" />
                    </a>
                    <a href="https://www.tiktok.com/@developbit" target="_blank" rel="noopener noreferrer">
                        <img src={TikTok} alt="TikTok" />
                    </a>
                </div>
            </div>
            <p>&copy; Aroepa-Fit</p>
            <div className="footer-links">
                <a href="/algemene-voorwaarden">Algemene voorwaarden</a>
                <a href="/privacybeleid">Privacybeleid</a>
                <a href="/disclaimer">Disclaimer</a>
                <a href="/contact">Contact</a>
            </div>
        </footer>
    );
}

export default Footer;
