import React from 'react';
import './Proefles.css';

function Proefles() {
    return (
        <section className="proefles" id="proefles">
            <h2>Proefles</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel venenatis tortor.</p>
        </section>
    );
}

export default Proefles;
