// HeroSection.js

import React from 'react';
import './HeroSection.css';
import {useNavigate} from "react-router-dom";
import {IoEnterOutline} from "react-icons/io5";

const HeroSection = () => {
    const navigate = useNavigate(); // Initialize useNavigate

    const handleRedirectConsult = () => {
        navigate('/'); // Redirect to SolarpanelAdmin
    };

    return (
        <div className="hero-section" id="hero-section">
            <h1>Aroepa-Fit</h1>
            <p>
                Lorem ipsum dolor sit amet.
            </p>
            <button onClick={handleRedirectConsult} className="rondleiding-button">
                🚀  Lorem ipsum. {/*insert space*/}<IoEnterOutline  className="rondleiding-icon"/>
            </button>
        </div>
    );
};

export default HeroSection;
