import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Header from "./components/Header";
import Homepage from "./components/Homepage";
import AboutUs from "./components/About";
import ProefTraining from "./components/Proefles";
import Price from "./components/Price";
import WhyChooseUs from "./components/WhyChooseUs";
import Begeleiding from "./components/Begeleiding";
import GroupTraining from "./components/GroupTraining";
import Service from "./components/Service";
import Footer from "./components/Footer";
import Training from "./components/Training";

function App() {
    return (
        <div>
            <Header/>
            <Routes>
                <Route path="/" element={<Homepage/>}/>
                <Route path="/over" element={<AboutUs/>}/>

                <Route path="/waarom-aroepafit" element={<WhyChooseUs/>}/>
                <Route path="/begeleiding" element={<Begeleiding/>}/>
                <Route path="/groepslessen" element={<GroupTraining/>}/>
                <Route path="/service" element={<Service/>}/>
                <Route path="/training" element={<Training/>}/>

                <Route path="/proef-training" element={<ProefTraining/>}/>
                <Route path="/prijzen" element={<Price/>}/>
            </Routes>
            <Footer/>
        </div>
    );
}

export default App;
