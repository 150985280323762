import React from "react";
import './GoogleMapsEmbed.css';

const GoogleMapsEmbed = () => {
    return (
        <div className="map-container">
            <iframe
                title="Google Maps"
                width="600"
                height="450"
                frameBorder="0"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2456.947727675522!2d4.1808299766490595!3d51.98960637192702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5b3dfba03cb7d%3A0xae0f27936a06c3bb!2sAROEPA%20FIT%20PERSONAL%20TRAINING%20STUDIO!5e0!3m2!1snl!2snl!4v1718383072471!5m2!1snl!2snl"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                style={{ height: "100%" }} /* Set the height of the iframe to 100% */
            >

            </iframe>

        </div>
    );
};

export default GoogleMapsEmbed;

