import React from 'react';

function About() {
    return (
        <div className="About">
            {/* Your application code here */}
        </div>
    );
}

export default About;
