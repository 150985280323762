import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import LogoAroepaFit from '../assets/LogoAroepaFit.png';

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    const [dropdowns, setDropdowns] = useState({});

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleDropdown = (index) => {
        setDropdowns(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const scrollTo = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleLinkClick = (id) => {
        closeMenu();
        scrollTo(id);
    };

    return (
        <header className="m-header">
            <div className="title-and-menu">
                <Link to="/" id="hero-section" className="title-and-menu-link">
                <h1>Aroepa-Fit</h1>
                <img src={LogoAroepaFit} alt="Aroepa-Fit Logo" className="header-logo"/>
                </Link>
                <div className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            </div>

            <nav className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                {/*<ul>*/}
                {/*    <li><Link to="/" onClick={() => handleLinkClick('why-aroepa')}>Waarom Aroepa-Fit</Link></li>*/}
                {/*</ul>*/}
                {/*//TODO Move this to Homepage content*/}
                <ul>
                    <li><a href="https://shop.aroepafit.nl/">Webshop</a></li>

                    <li><Link to="/" onClick={() => handleLinkClick('training')}>Training</Link></li>
                    <li><Link to="/" onClick={() => handleLinkClick('service')}>Service</Link></li>
                    <li><Link to="/" onClick={() => handleLinkClick('prices')}>Prijzen</Link></li>
                </ul>
                <button className="action-button">Lid worden</button>
            </nav>
        </header>
    );
}

export default Header;
