import React from 'react';
import './Service.css';

function Service() {
    return (
        <section className="service" id="service">
            <h2>Service</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel venenatis tortor.</p>
        </section>
    );
}

export default Service;
