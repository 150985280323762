import React from 'react';
import './Begeleiding.css';

function Begeleiding() {
    return (
        <section className="begeleiding" id="begeleiding">
            <h2>Begeleiding</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vel venenatis tortor.</p>
        </section>
    );
}

export default Begeleiding;
