import React from 'react';
import HeroSection from "./HeroSection";
import './Homepage.css';
import WhyChooseUs from "./WhyChooseUs";
import Location from "./Location";
import Price from "./Price";
import Service from "./Service";
import Training from "./Training";
import Gallery from "./Gallery";

function Homepage() {
    return (
        <div className="Homepage">
            <HeroSection />
            <WhyChooseUs />
            <Training />
            <Gallery />
            <Service />
            <Location />
            <Price />
        </div>
    );
}

export default Homepage;
//TODO TEST